.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 0;
    z-index: 9999;
  }
  
  .fullscreen iframe {
    width: 100%;
    height: 100%;
  }
  
/* Base scrollbar hiding */
.scrollbar-hide {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

/* Webkit specific */
.scrollbar-hide::-webkit-scrollbar,
.scrollbar-hide::-webkit-scrollbar-track,
.scrollbar-hide::-webkit-scrollbar-thumb {
  width: 0 !important;
  height: 0 !important;
  background-color: transparent !important;
  display: none !important;
}

/* Additional catch-all rules */
* {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

*::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

/* Handle WebKit fullscreen */
:fullscreen .fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Handle Mozilla fullscreen */
:-moz-full-screen .fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Handle Microsoft fullscreen */
:-ms-fullscreen .fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
